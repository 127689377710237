// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
//import GigandTakeSwitch from "@gigandtake/switch";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
	Autocomplete,
	CircularProgress,
	TextField as Text,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Snackbar from "@mui/material/Snackbar";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { differenceInHours } from "date-fns";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from "react";
import { useDispatch } from "react-redux";

import {
	createDraftShiftAsync,
	fetchAllWorkcenters,
} from "../../../../../../export/scheduler";
import { resetMaxWorkersByLocationJob } from "../../../../../components/jobs/store/jobs-slice";
import DateTimePicker from "../../../../../design/date-time-picker/date-time-picker";
import Toggle from "../../../../../design/toggle";
import JobColor from "../../../../../design/ui/job-color";
import useViewport from "../../../../../utils/use-viewport";
import useSchedulerData from "../../../controllers/use-scheduler-data";
import {
	fetchFilterAndShiftData,
	resetCreateDraftShiftAsyncState,
} from "../../../store/scheduler-slice";
import { SchedulerWorkCenter } from "../../../store/types";
import ActionButtons from "./action-buttons";
// import AddNote from "./add-note";
import Approve from "./approve";
import useNewShiftDialog from "./controllers/use-create-new-shift-dialog";
import { UseNewShiftDialogForm } from "./controllers/use-create-new-shift-dialog-form";
import EndTimePicker from "./end-time-picker";
import RepeatShift from "./repeat-shift";
import SelectSkills from "./select-skills";
import { setToNearestTime, useStyles } from "./utils";

const scrollBarStyles = {
	height: "550px",
	width: "100%",
	overflowY: "auto",
	overflowX: "hidden",
	"&::-webkit-scrollbar": {
		width: "8px",
	},
	"&::-webkit-scrollbar-track": {
		background: "#f1f1f1",
	},
	"&::-webkit-scrollbar-thumb": {
		background: "#888",
		borderRadius: "4px",
	},
	"&::-webkit-scrollbar-thumb:hover": {
		background: "#555",
	},
	"&::-webkit-scrollbar-corner": {
		background: "transparent",
	},
	"&::-webkit-resizer": {
		background: "transparent",
	},
	"&::-webkit-scrollbar-button": {
		display: "none",
	},
};

const shiftDay: number = new Date().getDay();
const initDays: Record<number, boolean> = {
	0: false,
	1: false,
	2: false,
	3: false,
	4: false,
	5: false,
	6: false,
	[shiftDay]: true,
};
const tempInitialShiftDateTimeValues = {
	startTime: "2022-10-19T10:00",
	endTime: "2022-10-19T18:00",
	workCenterId: 1,
	jobId: "",
	numberOfWorkersNeeded: "1",
	locationId: "",
	repeatShift: false,
	repeatOn: { ...initDays },
	shiftSkills: [],
	hexColor: "",
	shiftNote: "",
	shiftPurposeId: 0,
	allowPartialSignups: true,
};

interface FilledFormControlProps {
	children: ReactNode;
	customStyles?: Record<string, string | number>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const FilledFormControl = React.memo<FilledFormControlProps>(
	({ children, customStyles }) => {
		const classes = useStyles();
		return (
			<FormControl
				variant='filled'
				className={classes.formControlRoot}
				sx={{ ...customStyles }}
			>
				{children}
			</FormControl>
		);
	}
);

FilledFormControl.displayName = "FilledFormControl";

type NewShiftDialogProps = {
	draftShiftDate: Date;
	handleClose: (() => void) | undefined;
	isShiftSwap?: boolean;
	requestComponent?: any;
	isShiftCoverage?: boolean | undefined;
};

const NewShiftDialogForm: React.FC<NewShiftDialogProps> = ({
	draftShiftDate,
	handleClose,
	isShiftSwap,
	requestComponent,
	isShiftCoverage,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { width } = useViewport();
	const [
		{
			schema,
			initialShiftDateTimeValues,
			filteredJobs,
			selectedDatesOfTheWeek,
			totalHours,
			totalMins,
			shiftType,
		},
		{
			isDisabled,
			calculateTotalHoursFromEndTime,
			calculateTotalHoursFromStartTime,
			calculateTotalHoursFromWorkers,
			handleWorkcenterChange,
			getNumberOfStaff,
			setSelectedDatesOfTheWeek,
			setTotalHours,
			setShiftType,
			setCurrentLocationId,
		},
	] = useNewShiftDialog({ draftShiftDate });

	const [
		,
		{
			onFormSubmit,
			onEndTimeChange,
			onStartTimeChange,
			getLocationWorkcenters,
		},
	] = UseNewShiftDialogForm({
		setToNearestTime,
		dispatch,
		createDraftShiftAsync,
		setSelectedDatesOfTheWeek,
		calculateTotalHoursFromEndTime,
		calculateTotalHoursFromStartTime,
		isShiftSwap,
	});

	const {
		maxWorkersByJobLocation,
		fetchFilterAndShiftDataStatus,
		workcenters,
		filterAllWorkcenters,
		isShiftBySkillEnabled,
		defaultShiftsSkillLevel,
		allSkillLevels,
		fetchAllWorkcentersStatus,
		shiftPurposes,
		isShiftPurposeEnabled,
		createDraftShiftAsyncErrorMessage,
		createDraftShiftAsyncStatus,
		allowPartialSignups,
		minShiftHourDuration,
		maxShiftHourDuration,
		defaultShiftHourDuration,
		filterAllLocations,
	} = useSchedulerData();
	const locationWorkcenters =
		getLocationWorkcenters && getLocationWorkcenters(workcenters);

// For the toolip for Shift By Skill
		const skillLevel = allSkillLevels.find((s:any) => s.level === defaultShiftsSkillLevel);
		const levelName = skillLevel ? skillLevel.name : "N/A";

	const memoizedShiftValues = useMemo(
		() => ({
			jobId: initialShiftDateTimeValues?.jobId,
			locationId: initialShiftDateTimeValues?.locationId,
			startTime: initialShiftDateTimeValues?.startTime,
			endTime: initialShiftDateTimeValues?.endTime,
			workCenterId: initialShiftDateTimeValues?.workCenterId,
			shiftSkills: initialShiftDateTimeValues?.shiftSkills,
			allowPartialSignups: initialShiftDateTimeValues?.allowPartialSignups,
		}),
		[initialShiftDateTimeValues]
	);

	// Add this ref to track the last values we used
	const lastCalledValues = useRef("");

	useEffect(() => {
		if (memoizedShiftValues && memoizedShiftValues.workCenterId) {
			// Create a string representation of current values
			const currentValues = JSON.stringify({
				jobId: memoizedShiftValues.jobId,
				locationId: memoizedShiftValues.locationId,
				workCenterId: memoizedShiftValues.workCenterId,
				startTime: memoizedShiftValues.startTime,
				endTime: memoizedShiftValues.endTime,
				shiftSkills: memoizedShiftValues.shiftSkills,
				allowPartialSignups: memoizedShiftValues.allowPartialSignups,
			});

			// Only call if values have changed
			if (currentValues !== lastCalledValues.current) {
				getNumberOfStaff(
					memoizedShiftValues.jobId,
					memoizedShiftValues.locationId,
					memoizedShiftValues.workCenterId,
					new Date(memoizedShiftValues.startTime).toISOString(),
					new Date(memoizedShiftValues.endTime).toISOString(),
					memoizedShiftValues.shiftSkills,
					memoizedShiftValues.allowPartialSignups
				);
				lastCalledValues.current = currentValues;
			}
		}
	}, [getNumberOfStaff, memoizedShiftValues]);
	// This is to ensure API is called only when filterAllWorkcenters is not available
	// Which happen on page refresh
	useEffect(() => {
		if (
			fetchFilterAndShiftDataStatus === "idle" &&
			fetchAllWorkcentersStatus === "idle"
		) {
			dispatch(fetchAllWorkcenters());
			dispatch(fetchFilterAndShiftData());
		}
	}, [dispatch, fetchFilterAndShiftDataStatus, fetchAllWorkcentersStatus]);

	return (
		<Box
			width='810px'
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			// mr='auto'
			// ml='auto'
		>
			{isShiftSwap ? requestComponent : null}
			<Formik
				initialValues={
					initialShiftDateTimeValues
						? initialShiftDateTimeValues
						: tempInitialShiftDateTimeValues
				}
				validationSchema={schema}
				onSubmit={(values, actions) => {
					if (onFormSubmit) onFormSubmit(values, actions);
				}}
				enableReinitialize
			>
				{({ isValid, dirty, values, setFieldValue, isSubmitting, errors }) => (
					<Form className={classes.formRoot}>
						{/* <Box sx={scrollBarStyles}> */}
						{!isShiftPurposeEnabled ||
						(isShiftSwap && !isShiftCoverage) ? null : (
							<Box
								display='flex'
								justifyContent='space-between'
								flexDirection={width < 600 ? "column" : "row"}
							>
								<Box width={"100%"}>
									<FilledFormControl>
										<Field
											component={TextField}
											type='text'
											select
											variant='filled'
											name='shiftPurposeId'
											id='shiftPurposeId'
											label='Shift Purpose'
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												setFieldValue("shiftPurposeId", e.target.value);
											}}
											helperText=' '
											fullWidth
											InputLabelProps={{
												shrink: true, // Ensure the label shrinks properly
											}}
											SelectProps={{
												displayEmpty: true,
												renderValue: (value: any) => {
													if (!value || value === "") {
														return (
															<Typography
																sx={{
																	fontFamily: "Roboto",
																	fontSize: "16px",
																	color: "rgba(0, 0, 0, 0.6)",
																}}
															>
																Select a Purpose
															</Typography>
														);
													}
													const selectedPurpose = shiftPurposes?.[value];
													return selectedPurpose ? selectedPurpose.name : "";
												},
											}}
										>
											{shiftPurposes &&
												Object.values(shiftPurposes).map((purpose: any) => (
													<MenuItem
														value={purpose.id}
														key={`shift-purpose-${purpose.id}`}
													>
														{purpose.name}
													</MenuItem>
												))}
										</Field>
									</FilledFormControl>
								</Box>
							</Box>
						)}
						{isShiftSwap && !isShiftCoverage ? null : (
							<Box
								display='flex'
								justifyContent='space-between'
								flexDirection={width < 600 ? "column" : "row"}
								mt={isShiftSwap ? 2 : 0}
								mb={2.5}
							>
								<Box width={width < 600 ? "100%" : "48%"} mr={1}>
									{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
										<DateTimePicker
											label='Shift Start Time'
											value={
												values.startTime
													? dayjs(values.startTime, "YYYY-MM-DDTHH:mm")
													: null
											}
											format='MM/DD/YYYY, hh:mm A'
											onChange={(newValue) => {
												if (onStartTimeChange && getNumberOfStaff) {
													const formattedDate =
														dayjs(newValue).format("YYYY-MM-DDTHH:mm");
													onStartTimeChange(formattedDate, {
														setFieldValue,
														values,
													});
													getNumberOfStaff(
														values.jobId,
														values.locationId,
														values.workCenterId,
														new Date(formattedDate).toISOString(),
														new Date(values.endTime).toISOString(),
														values.shiftSkills,
														values.allowPartialSignups
													);
												}
											}}
											slotProps={{
												textField: {
													variant: "filled",
													fullWidth: true,
													InputLabelProps: {
														shrink: true,
													},
													error: errors.startTime ? true : false,
													helperText: errors.startTime ? errors.startTime : "",
												},
											}}
										/>
									</LocalizationProvider> */}

									<DateTimePicker
										label='Shift Start Time'
										value={values.startTime}
										onChange={(newValue: string) => {
											if (onStartTimeChange && getNumberOfStaff) {
												const formattedDate =
													dayjs(newValue).format("YYYY-MM-DDTHH:mm");
												onStartTimeChange(formattedDate, {
													setFieldValue,
													values,
												});
												getNumberOfStaff(
													values.jobId,
													values.locationId,
													values.workCenterId,
													new Date(formattedDate).toISOString(),
													new Date(values.endTime).toISOString(),
													values.shiftSkills,
													values.allowPartialSignups
												);
											}
										}}
										isError={errors.startTime ? true : false}
										helperText={errors.startTime ? errors.startTime : ""}
									/>
								</Box>
								<Box width={width < 600 ? "100%" : "48%"}>
									{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
										<DateTimePicker
											label='Shift End Time'
											value={
												values.endTime
													? dayjs(values.endTime, "YYYY-MM-DDTHH:mm")
													: null
											}
											format='MM/DD/YYYY, hh:mm A'
											onChange={(newValue) => {
												if (onEndTimeChange && getNumberOfStaff) {
													const formattedDate =
														dayjs(newValue).format("YYYY-MM-DDTHH:mm");
													onEndTimeChange(formattedDate, {
														setFieldValue,
														values,
													});
													getNumberOfStaff(
														values.jobId,
														values.locationId,
														values.workCenterId,
														new Date(values.startTime).toISOString(),
														new Date(formattedDate).toISOString(),
														values.shiftSkills,
														values.allowPartialSignups
													);
												}
											}}
											slotProps={{
												textField: {
													variant: "filled",
													fullWidth: true,
													InputLabelProps: {
														shrink: true,
													},
													error: errors.endTime ? true : false,
													helperText: errors.endTime ? errors.endTime : "",
												},
											}}
										/>
									</LocalizationProvider> */}
									<EndTimePicker
										minShiftHourDuration={minShiftHourDuration}
										maxShiftHourDuration={maxShiftHourDuration}
										defaultShiftHourDuration={defaultShiftHourDuration}
										values={values}
										setFieldValue={setFieldValue}
										onChange={(newValue: any) => {
											if (onEndTimeChange && getNumberOfStaff) {
												const formattedDate =
													dayjs(newValue).format("YYYY-MM-DDTHH:mm");
												onEndTimeChange(formattedDate, {
													setFieldValue,
													values,
												});
												getNumberOfStaff(
													values.jobId,
													values.locationId,
													values.workCenterId,
													new Date(values.startTime).toISOString(),
													new Date(formattedDate).toISOString(),
													values.shiftSkills,
													values.allowPartialSignups
												);
											}
										}}
										errors={errors}
									/>
								</Box>
							</Box>
						)}

						{isShiftSwap && !isShiftCoverage ? null : (
							<Box
								display='flex'
								justifyContent='space-between'
								flexDirection={width < 600 ? "column" : "row"}
							>
								<Box width={"100%"}>
									<FilledFormControl>
										<Field
											component={TextField}
											type='text'
											select
											variant='filled'
											name='workCenterId'
											id='workCenterId'
											label='Location - Work Center'
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												if (handleWorkcenterChange) {
													setFieldValue("workCenterId", e.target.value);
													handleWorkcenterChange(e.target.value, setFieldValue);
													// setFieldValue("jobId", "");
												}
												let locationWorkcenter = locationWorkcenters?.find(
													(lwc: any) => lwc.id === e.target.value
												);
												if (fetchFilterAndShiftDataStatus === "fulfilled") {
													locationWorkcenter = filterAllWorkcenters?.find(
														(workcenter: SchedulerWorkCenter) =>
															workcenter.id === parseInt(e.target.value)
													);
												}
												if (locationWorkcenter) {
													setFieldValue(
														"locationId",
														locationWorkcenter?.locationId
													);
													setCurrentLocationId(locationWorkcenter?.locationId);
													setFieldValue("jobId", "");
													setFieldValue("hexColor", "");
												}
												setFieldValue("allowPartialSignups", false);
											}}
											helperText=' '
											fullWidth
										>
											{fetchFilterAndShiftDataStatus === "fulfilled" &&
											filterAllWorkcenters
												? filterAllWorkcenters.map(
														(workcenter: SchedulerWorkCenter) => (
															<MenuItem
																value={`${workcenter.id}`}
																key={`workcenter-${workcenter.id}`}
																id={`${workcenter.id}`}
															>
																{workcenter.nameWithLocation}
															</MenuItem>
														)
												  )
												: locationWorkcenters &&
												  locationWorkcenters.map((lwc: any) => (
														<MenuItem value={lwc.id} key={lwc.id} id={lwc.id}>
															{lwc.locationName} - {lwc.name}
														</MenuItem>
												  ))}
										</Field>
									</FilledFormControl>
								</Box>
							</Box>
						)}

						{isShiftSwap && !isShiftCoverage ? null : (
							<Box
								display='flex'
								justifyContent='space-between'
								flexDirection={width < 600 ? "column" : "row"}
								mb={0}
							>
								<Box width={width < 600 ? "100%" : "48%"} mr={1}>
									<FilledFormControl>
										<Field
											component={TextField}
											type='number'
											variant='filled'
											name='numberOfWorkersNeeded'
											id='numberOfWorkersNeeded'
											label='Required Workers'
											disabled={isSubmitting}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												setFieldValue("numberOfWorkersNeeded", e.target.value);
												if (calculateTotalHoursFromWorkers)
													calculateTotalHoursFromWorkers(
														values,
														e.target.value
													);
											}}
										/>
									</FilledFormControl>
								</Box>
								<Box width={width < 600 ? "100%" : "48%"}>
									<FilledFormControl>
										<Field
											component={TextField}
											disabled
											value={
												totalHours !== undefined && totalMins !== undefined
													? `${
															totalHours <= 0 ? "0 Hrs" : `${totalHours} Hrs`
													  } ${
															totalMins <= 0 ? "0 Mins" : `${totalMins} Mins`
													  }`
													: ""
											}
											type='text'
											variant='filled'
											label='Total Hours'
											name='total-hours'
										/>
									</FilledFormControl>
								</Box>
							</Box>
						)}
						{(isShiftSwap && !isShiftCoverage) ||
						!isShiftBySkillEnabled ? null : (
							<Box mt={3} mb={1}>
								<Typography
									sx={{
										fontSize: "16px",
										fontWeight: "400",
										lineHeight: "24px",
										fontFamily: "Roboto",
									}}
								>
									Shift Type (Find Workers by)
								</Typography>
								<FormControl
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "flex-start",
									}}
								>
									<RadioGroup
										aria-labelledby='demo-controlled-radio-buttons-group'
										name='controlled-radio-buttons-group'
										value={shiftType}
										onChange={(e) => {
											if (e.target.value === "skills") {
												setFieldValue("jobId", "");
											} else {
												if (filteredJobs.length === 1) {
													setFieldValue("jobId", filteredJobs[0].id);
												}
												setFieldValue("shiftSkills", []);
											}
											setShiftType(e.target.value);
										}}
										sx={{ display: "flex", flexDirection: "row" }}
									>
										<FormControlLabel
											value='jobs'
											control={<Radio />}
											label='Job Role'
										/>
										<FormControlLabel
											value='skills'
											control={<Radio />}
											label='Skills'
										/>
									</RadioGroup>
									{shiftType === "skills" ? (
										<Tooltip
											// title='All default skill levels are set in the Admin section'
											title = {`Only Workers with a minimum competency of ${levelName} on all the selected Skills will be considered for the Shift. To change this, contact your administrator.`}
											arrow
											slotProps={{
												popper: {
													sx: {
														[`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
															{
																width: "150px",
															},
													},
												},
											}}
										>
											<InfoOutlinedIcon />
										</Tooltip>
									) : null}
								</FormControl>
							</Box>
						)}
						{shiftType === "skills" && isShiftBySkillEnabled ? (
							<SelectSkills
								values={values}
								setFieldValue={setFieldValue}
								getNumberOfStaff={getNumberOfStaff}
								resetMaxWorkersByLocationJob={() =>
									dispatch(resetMaxWorkersByLocationJob())
								}
							/>
						) : isShiftSwap && !isShiftCoverage ? null : (
							<Box width={"100%"} mt={isShiftBySkillEnabled ? 0 : 2.5}>
								<FilledFormControl>
									<Autocomplete
										options={filteredJobs ?? []}
										getOptionLabel={(option) => option.name}
										disabled={filteredJobs?.length === 0}
										value={
											filteredJobs?.find(
												(job: any) => job.id === values.jobId
											) ??
											(filteredJobs?.length === 1 && values.jobId !== ""
												? filteredJobs[0]
												: null)
										}
										onChange={(e, newValue) => {
											dispatch(resetMaxWorkersByLocationJob());
											let locationWorkcenter = locationWorkcenters?.find(
												(lwc: any) => lwc.id === values.workCenterId
											);
											if (fetchFilterAndShiftDataStatus === "fulfilled") {
												locationWorkcenter = filterAllWorkcenters?.find(
													(workcenter: SchedulerWorkCenter) =>
														workcenter.id ===
														parseInt(String(values.workCenterId))
												);
											}
											if (locationWorkcenter && getNumberOfStaff) {
												setFieldValue(
													"locationId",
													locationWorkcenter?.locationId
												);
												getNumberOfStaff(
													newValue?.id ?? "",
													locationWorkcenter.locationId,
													values.workCenterId,
													new Date(values.startTime).toISOString(),
													new Date(values.endTime).toISOString(),
													[],
													values.allowPartialSignups
												);
											}
											setFieldValue("jobId", newValue?.id ?? "");
											setFieldValue("hexColor", newValue?.hexColor ?? "");
										}}
										renderInput={(params) => {
											return (
												<Text
													{...params}
													variant='filled'
													label='Job Role'
													disabled={filteredJobs?.length === 0}
													InputProps={{
														...params.InputProps,
														startAdornment: values.jobId && (
															<JobColor color={`#${values.hexColor ?? ""}`} />
														),
													}}
												/>
											);
										}}
										renderOption={(props, option) => {
											const { key, ...otherProps } = props;
											return (
												<Box
													key={key}
													sx={{
														display: "flex",
														alignItems: "center",
														justifyContent: "flex-start",
														gap: "5px",
													}}
													component={"li"}
													{...otherProps}
												>
													<JobColor color={`#${option.hexColor}`} />
													<Typography>{option.name}</Typography>
												</Box>
											);
										}}
									/>
									<Typography
										variant='caption'
										sx={{
											marginTop: "2px",
											color:
												maxWorkersByJobLocation.data === 0 ? "red" : "auto",
										}}
									>
										{maxWorkersByJobLocation.status === "pending" ? (
											<CircularProgress size={20} />
										) : maxWorkersByJobLocation.status === "fulfilled" &&
										  values.jobId ? (
											maxWorkersByJobLocation.data + 
											// " worker(s) have this job"
											" Workers found"
										) : (
											""
										)}
									</Typography>
								</FilledFormControl>
							</Box>
						)}

						{isShiftSwap && !isShiftCoverage ? null : (
							<Box width={"100%"} mr={1} mt={2} mb={2}>
								<FilledFormControl>
									<Text
										type='text'
										variant='filled'
										name='note'
										id='note'
										label='Note to Workers (optional)'
										InputLabelProps={{ shrink: true }}
										placeholder='Eligible Workers will be able to see this note while applying for the Shift'
										multiline
										rows={2}
										disabled={isSubmitting}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setFieldValue("shiftNote", e.target.value);
										}}
										error={values.shiftNote.length > 250}
										helperText={
											values.shiftNote.length > 250
												? "Note should be less than 250 characters"
												: ""
										}
									/>
								</FilledFormControl>
								<Box display='flex' justifyContent='flex-end'>
									<Typography
										sx={{
											fontSize: "12px",
											fontWeight: "400",
											lineHeight: "19px",
											fontFamily: "Roboto",
											color: "#666666",
										}}
									>{`${values.shiftNote.length} / 250`}</Typography>
								</Box>
							</Box>
						)}

						{(() => {
							// Check if partial signups should be disabled
							const selectedWorkcenter = filterAllWorkcenters?.find(
								(workcenter: SchedulerWorkCenter) =>
									workcenter.id === Number(values.workCenterId)
							);

							const selectedLocation = filterAllLocations?.find(
								(location: { id: number; partialShiftMinHours: number }) =>
									location.id === Number(values.locationId)
							);

							const shiftDuration = differenceInHours(
								new Date(values.endTime),
								new Date(values.startTime)
							);

							const shouldDisablePartialSignups =
								shiftType === "skills" ||
								!values.workCenterId ||
								!selectedWorkcenter?.isPartialSignupEnabled ||
								(selectedLocation?.partialShiftMinHours ?? 0) > shiftDuration ||
								(isShiftSwap && !isShiftCoverage);

							if (shouldDisablePartialSignups) return null;

							return (
								<Box width='100%' mt={2}>
									<Box display='flex' alignItems='center'>
										<Toggle
											name='allowPartialSignups'
											id='allowPartialSignups'
											onChange={() => {
												if (getNumberOfStaff) {
													getNumberOfStaff(
														values.jobId,
														values.locationId,
														values.workCenterId,
														new Date(values.startTime).toISOString(),
														new Date(values.endTime).toISOString(),
														[],
														values.allowPartialSignups
													);
												}
												setFieldValue(
													"allowPartialSignups",
													!values.allowPartialSignups
												);
											}}
										/>
										<Box display='flex' flexDirection='column' ml={2}>
											<Typography
												sx={{
													fontSize: "16px",
													fontFamily: "Roboto",
													fontWeight: 400,
													letterSpacing: "0.15px",
													color: "#000000DE",
												}}
											>
												Allow Workers to sign up for partial Shift
											</Typography>
											<Typography
												sx={{
													fontSize: "12px",
													fontFamily: "Roboto",
													fontWeight: 400,
													letterSpacing: "0.4px",
													color: "#00000099",
												}}
											>
												Workers can confirm a portion of the Shift if they
												cannot attend the full Shift.
											</Typography>
										</Box>
									</Box>
								</Box>
							);
						})()}

						{!isShiftSwap ? (
							<Box width='100%' mt={3} mb={3}>
								<Box width='30%' display='flex' alignItems='center'>
									<FilledFormControl customStyles={{ width: "30% !important" }}>
										<Toggle
											name='repeatShift'
											id='repeatShift'
											//type='checkbox'
											onChange={() =>
												setFieldValue("repeatShift", !values.repeatShift)
											}
										/>
									</FilledFormControl>

									<Typography
										variant='caption'
										sx={{
											fontSize: "16px",
											fontFamily: "Roboto",
											fontWeight: 400,
											letterSpacing: "0.15px",
										}}
									>
										Repeat Shift
									</Typography>
								</Box>
							</Box>
						) : null}

						{!isShiftSwap && values.repeatShift ? (
							<RepeatShift
								values={values}
								setFieldValue={setFieldValue}
								selectedDatesOfTheWeek={selectedDatesOfTheWeek}
								width={width}
							/>
						) : null}
						{/* </Box> */}
						{/* Create Button */}
						{isShiftSwap && !isShiftCoverage ? (
							<Approve handleClose={handleClose} />
						) : (
							<ActionButtons
								handleClose={() => {
									handleClose && handleClose();
									dispatch(resetMaxWorkersByLocationJob());
								}}
								isValid={isValid}
								dirty={dirty}
								isSubmitting={isSubmitting}
								isShiftSwap={isShiftSwap}
								isDisabled={
									// Check if skills/job requirements are met
									(shiftType === "skills" && values.shiftSkills.length === 0) ||
									(shiftType !== "skills" && !values.jobId) ||
									// Check shift purpose if enabled
									(isShiftPurposeEnabled && !values.shiftPurposeId) ||
									// Check repeat shift settings
									(values.repeatShift &&
										!Object.values(values.repeatOn).some(Boolean)) ||
									// Check partial shift duration requirements
									(values.allowPartialSignups &&
										filterAllLocations?.find(
											(location: {
												id: number;
												partialShiftMinHours: number;
											}) => location.id === Number(values.locationId)
										)?.partialShiftMinHours >
											differenceInHours(
												new Date(values.endTime),
												new Date(values.startTime)
											))
								}
								jobSelected={!!values.jobId}
								skillSelected={!!values.shiftSkills.length}
								availableWorkers={maxWorkersByJobLocation.data ?? 0}
							/>
						)}
					</Form>
				)}
			</Formik>
			{createDraftShiftAsyncStatus === "error" && (
				<Snackbar
					open={createDraftShiftAsyncStatus === "error"}
					autoHideDuration={1000}
					onClose={() => {
						try {
							dispatch(resetCreateDraftShiftAsyncState());
						} catch (e) {
							// Do nothing
						}
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='error'>
						{createDraftShiftAsyncErrorMessage}
					</MuiAlert>
				</Snackbar>
			)}
		</Box>
	);
};

export default React.memo(NewShiftDialogForm);
