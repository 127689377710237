import isPast from "date-fns/isPast";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { fetchMaxWorkersByLocationJob } from "../../../../../../../js/features/ManageJobs/ManageJobsSlice";
import { calculateShiftHoursAndMinutes } from "../../../../../../utils/helpers";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import {
	configureDatesOfTheWeek,
	getInitValues,
	yupInitObject,
} from "../utils";

type JobRole = {
	id: number;
	isActive: boolean;
	name: string;
	hexColor: string;
};

type Shift = {
	id: number | string;
};

type FormValues = {
	startTime: string;
	endTime: string;
	numberOfWorkersNeeded: string;
};

const useEditShiftDialog = () => {
	const [filteredJobs, setFilteredJobs] = useState<JobRole[]>([]);

	const {
		allShiftsData,
		shiftIdForEditShift,
		defaultDurationHrs,
		minDurationHrs,
		maxDurationHrs,
		shiftId,
		filterJobRoles,
		filterAllWorkcenters,
		shiftPurposes,
	} = useSchedulerData();

	const dispatch = useDispatch();

	const currentShiftDetails = useCallback(() => {
		const shiftData = allShiftsData.filter(
			(shift: Shift) => shift.id.toString() === shiftIdForEditShift
		);
		return shiftData[0];
	}, [allShiftsData, shiftIdForEditShift]);

	const initialValues = useMemo(
		() =>
			getInitValues({
				allShiftsData,
				shiftIdForEditShift,
				currentShiftDetails,
				filterAllWorkcenters,
			}),
		[
			allShiftsData,
			shiftIdForEditShift,
			currentShiftDetails,
			filterAllWorkcenters,
		]
	);

	const schema = useMemo(() => Yup.object(yupInitObject), []);

	const [totalHours, setTotalHours] = useState(0);
	const [totalMins, setTotalMins] = useState(0);

	const [initialShiftDateTimeValues, setInitialShiftDateTimeValue] =
		useState(initialValues);
	const [selectedDatesOfTheWeek, setSelectedDatesOfTheWeek] = useState(() =>
		configureDatesOfTheWeek(new Date(initialValues.startTime))
	);

	const handleWorkcenterChange = useCallback(
		(workcenterId: string) => {
			const numericWorkcenterId = parseInt(workcenterId);
			const selectedWorkcenter = filterAllWorkcenters.find(
				(workcenter: { id: number }) => workcenter.id === numericWorkcenterId
			);

			if (selectedWorkcenter) {
				const jobIds = selectedWorkcenter.jobIds.split(",").map(Number);
				const jobs = filterJobRoles.filter(
					(job: JobRole) => jobIds.includes(job.id) && job.isActive
				);
				setFilteredJobs(jobs);
			} else {
				setFilteredJobs([]);
			}
		},
		[filterJobRoles, filterAllWorkcenters]
	);

	const getNumberOfStaff = useCallback(
		(
			jobRole: string | null,
			locationId: string,
			workCenterId: string | number,
			startTime: string,
			endTime: string,
			shiftSkills?: any[],
			allowPartialSignups?: boolean
		) => {
			if (!locationId || !startTime || !endTime || !workCenterId) return;
			if (jobRole === "" && shiftSkills?.length === 0) return;

			dispatch(
				fetchMaxWorkersByLocationJob({
					locationId,
					jobId: jobRole === "" ? null : jobRole,
					workcenterId: Number(workCenterId),
					startDateTime: startTime,
					endDateTime: endTime,
					shiftSkills,
					isPartialSignupEnabled: allowPartialSignups,
				})
			);
		},
		[dispatch]
	);

	const calculateHours = useCallback(
		(startTime: string, endTime: string, numberOfWorkersNeeded: string) => {
			const { hours, minutes } = calculateShiftHoursAndMinutes(
				startTime,
				endTime,
				numberOfWorkersNeeded
			);

			setTotalHours(hours);
			setTotalMins(minutes);
		},
		[]
	);

	const calculateTotalHoursFromWorkers = useCallback(
		(formValues: FormValues, numberOfWorkersNeeded: string) => {
			const { startTime, endTime } = formValues;
			if (startTime && endTime && numberOfWorkersNeeded) {
				calculateHours(startTime, endTime, numberOfWorkersNeeded);
			}
		},
		[calculateHours]
	);

	const calculateTotalHoursFromStartTime = (
		formValues: FormValues,
		startTime: string
	) => {
		const { numberOfWorkersNeeded, endTime } = formValues;
		if (startTime && endTime && numberOfWorkersNeeded) {
			calculateHours(startTime, endTime, numberOfWorkersNeeded);
		}
	};

	const calculateTotalHoursFromEndTime = (
		formValues: FormValues,
		endTime: string
	) => {
		const { numberOfWorkersNeeded, startTime } = formValues;
		if (startTime && endTime && numberOfWorkersNeeded) {
			calculateHours(startTime, endTime, numberOfWorkersNeeded);
		}
	};

	const foundJob = (jobId: number) => {
		return filteredJobs.some((job: JobRole) => job.id === jobId);
	};

	useEffect(() => {
		const initValues = getInitValues({
			allShiftsData,
			shiftIdForEditShift,
			currentShiftDetails,
			filterAllWorkcenters,
		});

		handleWorkcenterChange(initValues.workCenterId);
		getNumberOfStaff(
			initValues.jobId ?? null,
			initValues.locationId,
			initValues.workCenterId,
			initValues.startTime,
			initValues.endTime,
			initValues.shiftSkills
		);
		calculateTotalHoursFromWorkers(
			initValues,
			initValues.numberOfWorkersNeeded
		);
	}, [
		defaultDurationHrs,
		allShiftsData,
		shiftIdForEditShift,
		currentShiftDetails,
		minDurationHrs,
		maxDurationHrs,
		handleWorkcenterChange,
		getNumberOfStaff,
		calculateTotalHoursFromWorkers,
		filterAllWorkcenters,
	]);

	const isDisabled = (currentDate: Date) => {
		return isPast(currentDate) || false;
	};

	return [
		{
			initialValues,
			schema,
			initialShiftDateTimeValues,
			filteredJobs,
			selectedDatesOfTheWeek,
			totalHours,
			totalMins,
			shiftId,
		},
		{
			isDisabled,
			calculateTotalHoursFromEndTime,
			calculateTotalHoursFromStartTime,
			calculateTotalHoursFromWorkers,
			handleWorkcenterChange,
			getNumberOfStaff,
			setInitialShiftDateTimeValue,
			setSelectedDatesOfTheWeek,
			setTotalHours,
			foundJob,
		},
	] as const;
};

export default useEditShiftDialog;
